import { useAtom } from "@reatom/npm-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Flex } from "antd";
import { FC, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { StripePaymentDetails } from "@/features/payment-details";
import { BillingWrapper } from "@/entities/payment";
import { BackgroundPreLogin, viewerRegisterAtom } from "@/entities/viewer";
import { getPaymentCredentialsResource, PaymentCredentials } from "@/shared/api/payment";
import { centsToDollars } from "@/shared/methods";
import { Spinner } from "@/shared/ui";

import { PaymentSchedule } from "./payment-schedule";
import { PaymentSummary } from "./payment-summary";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY, { locale: "en" });

export const Payment: FC = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [viewer] = useAtom(viewerRegisterAtom);
	const [customer, setCustomer] = useState<PaymentCredentials | null>(null);

	useEffect(() => {
		const init = async () => {
			try {
				setLoading(true);
				if (viewer) {
					const { data: credentials } = await getPaymentCredentialsResource(viewer.key);

					if (credentials === null) {
						navigate("/plan");
					} else {
						if (credentials?.message === "Subscription was voided, please renew") {
							navigate("/plan");
						} else {
							setCustomer({
								...credentials,
								upcoming_invoice: {
									...credentials.upcoming_invoice,
									amount: centsToDollars(credentials.upcoming_invoice.amount)
								},
								current_invoice: {
									...credentials.current_invoice,
									amount: centsToDollars(credentials.current_invoice.amount)
								}
							});
						}
					}
				}
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		};

		init();
	}, []);

	return (
		<BackgroundPreLogin>
			<BillingWrapper>
				<div style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					overflowY: "scroll",
					width: "100%",
					height: "100%"
				}}>
					{loading && <Flex align="center" justify="center"><Spinner/></Flex>}
					{customer && (
						<Elements stripe={stripePromise} options={{
							clientSecret: customer.client_secret
						}}>
							<Flex className="gap-m" vertical>
								<PaymentSummary sub={customer}/>
								<PaymentSchedule sub={customer} />
								<StripePaymentDetails />
							</Flex>
						</Elements>
					)}
				</div>
			</BillingWrapper>
		</BackgroundPreLogin>
	);
};

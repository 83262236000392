import { useAtom } from "@reatom/npm-react";
import { Button, Flex, Input, Typography } from "antd";
import cn from "classnames";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useBehaviour } from "@/pages/plan/model";
import { BackgroundPreLogin, LoginWrapper, viewerRegisterAtom } from "@/entities/viewer";
import { checkPromoCodeResource } from "@/shared/api/payment";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

export const PromoCode: FC = () => {
	const { interval, productId } = useParams();
	const { isMobileL } = useMediaQueries();
	const [viewer] = useAtom(viewerRegisterAtom);
	const [promoCode, setPromoCode] = useState("");
	const { subscribe } = useBehaviour();
	const [loading, setLoading] = useState(false);

	const onApply = async () => {
		try {
			setLoading(true);
			if (viewer?.key && interval && productId) {
				const { data } = await checkPromoCodeResource(viewer.key, promoCode, productId);
				await subscribe({
					interval,
					token: viewer.key,
					promoCode: data?.code ?? "",
					productId: Number(productId)
				});
			}
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	const onSkip = async () => {
		try {
			setLoading(true);
			if (viewer?.key && interval && productId) {
				await subscribe({
					interval,
					token: viewer.key,
					promoCode: "",
					productId: Number(productId)
				});
			}
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper isChildrenFullHeight title="" questionText="" actionText="" action={() => {}}>
				<Flex align="center" className="full-width full-height">
					<Flex vertical gap={16}>
						<Flex vertical className="full-width gap-xs">
							<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH4, {
								[TEXT_CLASSNAMES.XsSemibold]: isMobileL
							})}>
								Make sure to apply your promo code now!
							</Typography.Text>
							<Flex className="gap-xs">
								<Input
									onChange={(event) => setPromoCode(event.target.value)}
									placeholder="Enter code here"
								/>
							</Flex>
						</Flex>
						<Flex className="full-width" gap={8}>
							<Button
								loading={loading}
								className="full-width"
								onClick={onApply}
								type="primary"
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Apply</Typography.Text>
							</Button>
							<Button
								loading={loading}
								className="full-width"
								onClick={onSkip}
							>
								<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Skip</Typography.Text>
							</Button>
						</Flex>
					</Flex>
				</Flex>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

import { FC } from "react";
import { VerticalSwiperItem } from "@/features/landing-vertical-swiper";
import { UseCaseTemplate } from "@/entities/use-cases";

import line from "@/assets/use-cases/video/line.svg";
import videoPreview from "@/assets/use-cases/video/preview.svg";
import slide1 from "@/assets/use-cases/video/slide-1.svg";
import slide2 from "@/assets/use-cases/video/slide-2.png";
import slide3 from "@/assets/use-cases/video/slide-3.svg";
import slide4 from "@/assets/use-cases/video/slide-4.svg";
import slide5 from "@/assets/use-cases/video/slide-5.svg";
import slide6 from "@/assets/use-cases/video/slide-6.svg";

export const UseCaseVideoNew: FC = () => {
	const items: VerticalSwiperItem[] = [
		{
			title: "Storyboard views",
			description: "View your storyboard in multiple formats, including shot lists and tiled layouts, allowing you to choose the perspective that best suits your planning needs.",
			link_1: {
				url: slide1,
				flex: 2,
				isContain: true
			},
			link_2: {
				url: slide2,
				flex: 3,
				isContain: true
			}
		},
		{
			title: "Different themes and preview",
			description: "Easily adapt the theme of your pitch deck to align with your project’s vision and style, ensuring that it that it is tailored to your needs.",
			link_1: {
				url: slide3,
				flex: 3,
				isContain: true
			},
			link_2: {
				url: slide4,
				flex: 2,
				padding: "32px 32px 0 32px",
				isContain: true
			}
		},
		{
			title: "Pitch deck customization",
			description: "Select the specific preproduction documents to include in your pitch deck and customize the color scheme to create a presentation that truly reflects your project's identity.",
			link_1: {
				url: slide5,
				flex: 2,
				isContain: true
			},
			link_2: {
				url: slide6,
				flex: 3,
				isContain: true
			}
		}
	];

	return (
		<UseCaseTemplate
			isComingSoon={true}
			items={items}
			description="Easily configure themes, content settings, and layouts to match your unique vision. Customize fonts, colors, and display options for a professional, tailored presentation."
			italicHeaderText="in Minutes"
			headerText={<>Create a Custom Movie Pitch <br/> Deck</>}
			lineImg={line}
			previewImg={videoPreview}
			lineClassName="use-case-template__line--video"
		/>
	);
};

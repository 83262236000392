import { useAtom } from "@reatom/npm-react";
import { Collapse, ConfigProvider, Flex } from "antd";
import { FC } from "react";
import { storyboardViewAtom } from "@/features/storyboard-view";
import { StoryboardGrid } from "@/features/storyboard-view/ui/StoryboardGrid";
import { StoryboardList } from "@/features/storyboard-view/ui/StoryboardList";
import { SceneHeader, TStoryboardScene } from "@/entities/storyboard";
import { COLOR_NAMES } from "@/shared/const/color-names.ts";

type Props = {
	scene: TStoryboardScene;
}

export const CollapsedScene: FC<Props> = ({ scene }) => {
	const [tableView] = useAtom(storyboardViewAtom);

	return (
		<ConfigProvider theme={{
			components: {
				Collapse: {
					fontSizeIcon: 18,
					headerBg: COLOR_NAMES.MetalGray50,
					contentBg: COLOR_NAMES.MetalGray50,
					colorBorder: COLOR_NAMES.MetalGray50,
					headerPadding: "16px",
					contentPadding: "0 16px 16px 16px",
					borderRadiusLG: 16
				}
			}
		}}>
			<Collapse
				expandIconPosition={"end"}
				items={[
					{
						key: scene.id,
						label: (
							<SceneHeader
								locations={scene.locations}
								idx={scene.idx}
								title={scene.title}
								shotLength={scene.shots.length}
								activeLocation={scene.locations[0]}
								projectLocation={scene.projectLocation}
							/>),
						children: (
							<Flex vertical className="gap-xs">
								{tableView === "grid" && <StoryboardGrid shots={scene.shots} sceneId={scene.id} />}
								{tableView === "list" && <StoryboardList shots={scene.shots} sceneId={scene.id} />}
							</Flex>
						)
					}
				]}
				defaultActiveKey={[scene.id]}
			/>
		</ConfigProvider>
	);
};

import { Button, Flex, Typography } from "antd";
import { FC } from "react";

import { SettingsWrapper } from "@/entities/settings";
import { NOT_RELEASE } from "@/shared/const/release";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import google from "@/assets/settings/profile/google.svg";

export const AccountSettingsForm: FC = () => {
	return (
		<SettingsWrapper
			title="Account Settings"
			description="Update your personal details here"
			className="gap-s full-width"
		>
			<Flex vertical className="gap-s">
				{NOT_RELEASE && (
					<Flex justify="space-between" align="center">
						<Flex vertical className="gap-xxs">
							<Flex className="gap-xxs" align="center">
								<img src={google} alt="google" />
								<Typography.Text className={TEXT_CLASSNAMES.XsSemibold}>Link Google account</Typography.Text>
							</Flex>
							<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>
								Update your personal details here.
							</Typography.Text>
						</Flex>
						<Button>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>
								Connect Google account
							</Typography.Text>
						</Button>
					</Flex>
				)}
			</Flex>
		</SettingsWrapper>
	);
};

import { useGoogleLogin } from "@react-oauth/google";
import { useAction, useAtom } from "@reatom/npm-react";
import { Button, Flex, Form, Input, Progress, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { BackgroundPreLogin, LoginWrapper, PLAN_EMAIL, PlanEmail, viewerAtom } from "@/entities/viewer";
import googleIcon from "@/assets/shared/google_icon.svg";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useLocalStorage } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries";
import { DisableSubmitButton } from "@/shared/ui";
import { validateEmail, validatePassword } from "@/shared/validation";

import { createUserAction, createUserGoogleAction, errorAtom, isSuccessEmailSendAtom } from "../model";

import "./Register.scss";

type FieldType = {
	username: string;
	email: string;
	password: string;
};

export const Register: FC = () => {
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { isMobileL } = useMediaQueries();
	const register = useAction(createUserAction);
	const googleRegister = useAction(createUserGoogleAction);
	const [_, setUserData] = useLocalStorage<PlanEmail | null>(PLAN_EMAIL, null);
	const [viewer] = useAtom(viewerAtom);
	const [error] = useAtom(errorAtom);
	const [isSuccessEmailSend] = useAtom(isSuccessEmailSendAtom);

	const email = Form.useWatch("email", { form, preserve: true });
	const username = Form.useWatch("username", { form, preserve: true });
	const password = Form.useWatch("password", { form, preserve: true });
	const handleRedirectToLogin = () => {
		navigate("/login");
	};

	const onDocumentRedirect = (href: string) => {
		const anchor = document.createElement("a");
		anchor.href = href;
		anchor.target = "_blank";
		anchor.click();
	};

	const login = useGoogleLogin({
		onSuccess: async tokenResponse => {
			googleRegister({ access_token: tokenResponse.access_token });
		},
		onError: (errorResponse) => console.log("==========>errorResponse", errorResponse)
	});

	const onFinish = (data: FieldType) => {
		register({
			email: data.email,
			password: data.password,
			name: data.username
		});
	};

	useEffect(() => {
		if (viewer?.key) {
			navigate("/main/projects");
		}
	}, [viewer]);

	useEffect(() => {
		if (isSuccessEmailSend) {
			setUserData({ email });
			navigate(`/success-email/${email}`);
		}
	}, [isSuccessEmailSend]);
	const validatedPassword = validatePassword(password);
	const values = Object.values(validatedPassword).filter((value) => value);

	const colors = () => {
		switch (values.length) {
		case 0:
			return "";
		case 1:
			return COLOR_NAMES.HeartRed500;
		case 2:
			return COLOR_NAMES.HeartRed400;
		case 3:
			return COLOR_NAMES.CrazyOrange500;
		case 4:
			return COLOR_NAMES.CrazyOrange400;
		case 5:
			return COLOR_NAMES.Green500;
		default:
			return COLOR_NAMES.Green500;
		}
	};

	const percent = () => {
		const val = colors();

		switch (val) {
		case COLOR_NAMES.HeartRed500:
			return 20;
		case COLOR_NAMES.HeartRed400:
			return 40;
		case COLOR_NAMES.CrazyOrange500:
			return 60;
		case COLOR_NAMES.CrazyOrange400:
			return 80;
		case COLOR_NAMES.Green500:
			return 100;
		default:
			return 0;
		}
	};

	return (
		<BackgroundPreLogin>
			<LoginWrapper
				action={handleRedirectToLogin}
				title="Get started"
				questionText="Already have an account? "
				actionText="Sign in"
			>
				<Form
					form={form}
					name="basic"
					initialValues={{ remember: true }}
					onFinish={onFinish}
					autoComplete="off"
					className="register-form"
					validateTrigger={["onSubmit"]}
				>
					<Flex vertical className="gap-xs">
						<Form.Item<FieldType>
							name="username"
							rules={[
								{ required: true, message: "Please input your name!" }
							]}
							hasFeedback
							validateStatus={username ? "success" : ""}
						>
							<Input
								onChange={(event) => form.setFieldValue("username", event.target.value)}
								placeholder="Name"
							/>
						</Form.Item>
						<Form.Item<FieldType>
							name="email"
							rules={[
								{ required: true, message: "Please input your email!" },
								{ type: "email", message: "The input is not valid E-mail!" }
							]}
							hasFeedback
							validateStatus={validateEmail(email)}
						>
							<Input
								onChange={(event) => form.setFieldValue("email", event.target.value)}
								placeholder="Email"
							/>
						</Form.Item>
						<Form.Item<FieldType>
							name="password"
							style={{
								maxWidth: 380
							}}
							rules={[
								{ required: true, message: "Please input your password!" },
								{ min: 8, message: "Password must be at least 8 characters" }
							]}
						>
							<Input.Password
								onChange={(event) => form.setFieldValue("password", event.target.value)}
								placeholder="Password"
							/>
							<Progress strokeColor={colors()} percent={percent()} showInfo={false} />
							<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>Password must be at least 8 characters long</Typography.Text>
						</Form.Item>
					</Flex>
					<Flex vertical className="register-form__buttons gap-xs">
						<DisableSubmitButton errorMessage={error} type="primary" form={form}>
							<Typography.Text className={cn(TEXT_CLASSNAMES.XsRegular)}>
								Sign up
							</Typography.Text>
						</DisableSubmitButton>
						<Button
							htmlType="button"
							className="register-form__buttons__google"
							icon={<img src={googleIcon} alt="google icon" />}
							onClick={() => login()}
						>
							<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.XsRegular)}>
								Sign up with Google
							</Typography.Text>
						</Button>
					</Flex>
					<Typography.Text className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, {
						[TEXT_CLASSNAMES.XsRegular]: isMobileL
					})}>
						By signing up I agree with
						<Typography.Text
							onClick={() => onDocumentRedirect("/terms-of-conditions")}
							className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "register-form__underline pointer", {
								[TEXT_CLASSNAMES.XsRegular]: isMobileL
							})}>
							Terms of Service
						</Typography.Text>
						{!isMobileL && <br/>}
						and
						<Typography.Text
							onClick={() => onDocumentRedirect("/privacy-policy")}
							className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "register-form__underline pointer", {
								[TEXT_CLASSNAMES.XsRegular]: isMobileL
							})}>
							Privacy Policy
						</Typography.Text>
						and
						<Typography.Text
							onClick={() => onDocumentRedirect("/acceptable-use-policy")}
							className={cn(COLOR_CLASSNAMES.TextSecondary, TEXT_CLASSNAMES.SmRegular, "register-form__underline pointer", {
								[TEXT_CLASSNAMES.XsRegular]: isMobileL
							})}>
							Acceptable Use Policy
						</Typography.Text>
					</Typography.Text>
				</Form>
			</LoginWrapper>
		</BackgroundPreLogin>
	);
};

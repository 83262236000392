import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useState } from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";

import { useNavigate } from "react-router-dom";
import { LandingVerticalSwiper, VerticalSwiperItem } from "@/features/landing-vertical-swiper";
import { RequestAccessModal } from "@/features/request-access-modal";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useOpenModal } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import ba1 from "@/assets/landing/ba-1.png";
import ba2 from "@/assets/landing/ba-2.png";
import ba3 from "@/assets/landing/ba-3.png";

import video1 from "@/assets/landing/gif-1.gif";
import video2 from "@/assets/landing/gif-2.gif";
import video3 from "@/assets/landing/gif-3.gif";
import video4 from "@/assets/landing/gif-4.gif";
import video5 from "@/assets/landing/gif-5.gif";
import video6 from "@/assets/landing/gif-6.gif";
import line1024 from "@/assets/landing/line-1024.svg";
import line1280 from "@/assets/landing/line-1280.svg";
import line1440 from "@/assets/landing/line-1440.svg";
import line320 from "@/assets/landing/line-320.svg";
import line768 from "@/assets/landing/line-744.svg";
import video10 from "@/assets/landing/video-10.svg";
import video7 from "@/assets/landing/video-7.svg";
import video8 from "@/assets/landing/video-8.svg";
import video9 from "@/assets/landing/video-9.svg";

import "react-before-after-slider-component/dist/build.css";
import "./ProductNew.scss";

const FIRST_IMAGE = {
	imageUrl: ba1
};
const SECOND_IMAGE = {
	imageUrl: ba2
};

export const ProductNew: FC = () => {
	const { isTablet, isLaptopS, isMobileXL, isLaptopM } = useMediaQueries();
	const [isStoryboard, setIsStroryboard] = useState(false);
	const [percent, setPercent] = useState(15);
	const [isFinalize, setIsFinalize] = useState(false);
	const [open, toggleOpen] = useOpenModal();
	const navigate = useNavigate();

	const login = () => {
		navigate("/login");
	};

	const onFinalize = () => {
		setIsFinalize(true);
		setIsStroryboard(false);
	};

	const onChanePercentPosition = (percent: number) => {
		setPercent(percent);

		if (percent > 90) {
			setIsStroryboard(true);
		} else {
			setIsStroryboard(false);
		}
	};

	const USE_CASES = (isMobileXL: boolean) => [
		{
			title: "Effortless script breakdowns made simple",
			description: "Instantly turn your screenplay into a comprehensive shot list with our easy-to-use script editor",
			style: {
				position: "relative"
			}
		},
		{
			title: "Instantly transform scripts into storyboards with AI",
			description: "Generate storyboards and moodboards smoothly from your script",
			style: !isMobileXL
				? {
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)"
				}
				: {
					position: "relative"
				}
		},
		{
			title: "Generate your pitch deck",
			description: "Turn your preproduction documents into a polished pitch deck in seconds, capturing your creative vision with professional flair",
			style: {
				position: "relative"
			}
		}
	];

	const line = () => {
		if (isMobileXL) {
			return line320;
		}

		if (isTablet) {
			return line768;
		}

		if (isLaptopS) {
			return line1024;
		}

		if (isLaptopM) {
			return line1280;
		}

		return line1440;
	};

	const items: VerticalSwiperItem[] = [
		{
			title: "Effortless script breakdowns" + "\n" + " made simple",
			description: "Instantly turn your screenplay into a comprehensive shot list with our easy-to-use script editor.",
			link_1: {
				url: video1,
				flex: 1,
				padding: "32px 32px 0 32px"
			},
			link_2: {
				url: video2,
				flex: 2
			}
		},
		{
			title: "Scene-Specific Venue Suggestions",
			description: "Receive tailored recommendations for shooting venues that match the mood and requirements of each scene in your project.",
			link_1: {
				url: video9,
				flex: 1,
				padding: "32px 32px 0 32px",
				isContain: true
			},
			link_2: {
				url: video10,
				flex: 2,
				padding: "0 32px",
				isContain: true
			}
		},
		{
			title: "Instantly transform scripts into storyboards with AI",
			description: "Generate storyboards and moodboards smoothly from your script.",
			link_1: {
				url: video3,
				flex: 2
			},
			link_2: {
				url: video4,
				flex: 1
			}
		},
		{
			title: "Generate your pitch deck",
			description: "Turn your preproduction documents into a polished pitch deck in seconds, capturing your creative vision with professional flair.",
			link_1: {
				url: video7,
				flex: 2,
				isContain: true
			},
			link_2: {
				url: video8,
				flex: 1,
				isContain: true
			}
		},
		{
			title: "Built for collaboration",
			description: "Share and exchange ideas with your team right inside Blooper, enhancing collaboration and keeping everyone aligned with ease.",
			link_1: {
				url: video5,
				flex: 2,
				isContain: true
			},
			link_2: {
				url: video6,
				flex: 2
			},
			comingSoon: true
		}
	];

	useEffect(() => {
		const element = document.querySelector(".fake-header");

		if (element) {
			element?.scrollIntoView({ behavior: "smooth" });
		}
	}, []);

	return (
		<Flex vertical className="product-new">
			<Flex vertical className="product-new__title gap-xs text-center">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
				})}>
					Your video <br/>
					pre-production {isTablet && <br/>}made <span className="product-new__title--italic">easy</span>
				</Typography.Text>
			</Flex>
			<Flex vertical justify="center" align="center" className={cn("product-new__action text-center gap-m", {
				"gap-xs": isTablet
			})}>
				<img className="product-new__action--line" src={line()} alt="line"/>
				<Typography.Text className={cn(TEXT_CLASSNAMES.LgRegular, {
					[TEXT_CLASSNAMES.MdRegular]: isLaptopS,
					[TEXT_CLASSNAMES.SmRegular]: isTablet,
					[TEXT_CLASSNAMES.XsRegular]: isMobileXL
				})}>
					Helping creators drive the evolution <br/> of video production with Blooper
				</Typography.Text>
				<Button
					type="primary"
					style={{ width: isTablet ? 148 : 180, height: isTablet ? 40 : 54, borderRadius: 64 }}
					onClick={login}
				>
					<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
						[TEXT_CLASSNAMES.XsRegular]: isMobileXL
					})}>Try now</Typography.Text>
				</Button>
				<RequestAccessModal isOpen={open} toggleModal={toggleOpen} />
			</Flex>
			<Flex className="product-new__slider-container">
				<Flex align="center" justify="center" className={cn("product-new__slider-container__button", {
					"product-new__slider-container__button--hide": !isStoryboard
				})}>
					<Button onClick={onFinalize} type="primary" className="product-new__slider-container__button--button">
						<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, {
							[TEXT_CLASSNAMES.XxsRegular]: isMobileXL
						})}>Finalize pre-production</Typography.Text>
					</Button>
				</Flex>
				<Flex className="product-new__ba-wrapper">
					<Flex className={cn("product-new__ba-slider--image cubic-animation", {
						"product-new__ba-slider--active": isFinalize
					})}>
						<img alt="storyboard" src={ba3} className="image-contain" />
					</Flex>
					<ReactBeforeSliderComponent
						firstImage={FIRST_IMAGE}
						secondImage={SECOND_IMAGE}
						delimiterColor={COLOR_NAMES.Green500}
						className={cn("product-new__ba-slider", {
							"product-new__ba-slider--unactive": isStoryboard,
							"product-new__ba-slider--hide": isFinalize
						})}
						currentPercentPosition={percent}
						onChangePercentPosition={onChanePercentPosition}
					/>
				</Flex>
			</Flex>
			<Flex vertical className={cn("gap-l product-new__use-cases", {
				"gap-m": isTablet
			})}>
				<Typography.Text style={{ textTransform: "uppercase" }} className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
				})}>
					<span className="product-new__title--italic">Use </span>
					Cases
				</Typography.Text>
				<Flex
					style={{ position: "relative" }}
					vertical={isTablet}
					justify="space-between"
					align={isTablet ? "flex-start" : "center"}
					className={cn({
						"gap-s": isTablet
					})}>
					{USE_CASES(isTablet).map((el) => (
						<Flex
							vertical
							justify="center"
							align="center"
							className="gap-xs product-new__use-cases__item"
							// @ts-ignore
							style={el.style}
						>
							<Flex
								vertical
								justify="center"
								align="center"
								className={cn("product-new__use-cases__item--text", {
									"gap-xxs": isTablet
								})}
							>
								<Typography.Text className={cn(TEXT_CLASSNAMES.HeadlineH1, {
									[TEXT_CLASSNAMES.HeadlineH3]: isLaptopS,
									[TEXT_CLASSNAMES.XsBold]: isTablet
								})}>{el.title}</Typography.Text>
								<Typography.Text className={cn(TEXT_CLASSNAMES.MdRegular, COLOR_CLASSNAMES.TextSecondary, {
									[TEXT_CLASSNAMES.XsRegular]: isLaptopS,
									[TEXT_CLASSNAMES.XxsRegular]: isTablet
								})}>
									{el.description}</Typography.Text>
							</Flex>
						</Flex>
					))}
				</Flex>
			</Flex>
			<LandingVerticalSwiper items={items}/>
		</Flex>
	);
};

import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "./LandingVerticalSwiper.scss";

export type VerticalSwiperLink = {
	flex: number;
	url: string;
	align?: "flex-start" | "center" | "flex-end";
	justify?: "flex-start" | "center" | "flex-end";
	padding?: string;
	isContain?: boolean;
	Action?: JSX.Element
}
export type VerticalSwiperItem = {
	title: string;
	description: string;
	link_1: VerticalSwiperLink;
	link_2: VerticalSwiperLink;
	comingSoon?: boolean;
}

type Props = {
	items: VerticalSwiperItem[];
}

const Title = ({ title }: { title: string }) => {
	const { isTablet, isLaptopL, isMobileXL } = useMediaQueries();
	return (
		<Typography.Text style={{ maxWidth: 820 }} className={cn(TEXT_CLASSNAMES.LPHeadlineH2, {
			[TEXT_CLASSNAMES.LPHeadlineH3]: isLaptopL,
			[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet,
			[TEXT_CLASSNAMES.HeadlineH2]: isMobileXL
		})}>{title}</Typography.Text>
	);
};

const Description = ({ description }: { description: string; }) => {
	const { isTablet, isLaptopL, isMobileXL } = useMediaQueries();
	return (
		<Typography.Text className={cn(TEXT_CLASSNAMES.LgRegular, {
			[TEXT_CLASSNAMES.MdRegular]: isLaptopL,
			[TEXT_CLASSNAMES.SmRegular]: isTablet,
			[TEXT_CLASSNAMES.XsRegular]: isMobileXL
		})}>{description}</Typography.Text>
	);
};

export const LandingVerticalSwiper: FC<Props> = ({ items }) => {
	const { isMobileXL } = useMediaQueries();

	return (
		<Swiper
			direction={"vertical"}
			style={{ cursor: "grab" }}
			pagination={{
				clickable: true
			}}
			modules={[Pagination]}
		>
			{items.map((item, index) => (
				<SwiperSlide key={index.toString(36)}>
					<Flex justify="flex-end" className="full-height full-width">
						<Flex align="flex-start" vertical className={cn("gap-s slide-container", {
							"gap-xxs": isMobileXL
						})}>
							{item.comingSoon && (
								<Flex className="slide-container__coming-soon">
									<Typography.Text className="slide-container__coming-soon-title">
										Coming soon
									</Typography.Text>
								</Flex>
							)}
							<Flex vertical className="gap-xxs">
								<Title title={item.title} />
								<Description description={item.description} />
							</Flex>
							<Flex vertical={isMobileXL} className={cn("gap-m full-width full-height slide-container__content", {
								"gap-xs": isMobileXL
							})}>
								{!!item.link_1.url && (
									<Flex style={{
										padding: item.link_1.padding ?? 32,
										position: "relative"
									}} className="slide-container__content--item" flex={item.link_1.flex} justify={item.link_1.justify ?? "center"} align={item.link_1.align ?? "center"}>
										<img style={{ borderRadius: 16 }} src={item.link_1.url} className={cn({
											"image-cover": !item.link_1.isContain,
											"image-contain": item.link_1.isContain
										})} alt="video"/>
										<Flex align="center" justify="center" style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }}>
											{item.link_1.Action}
										</Flex>
									</Flex>
								)}
								{!!item.link_2.url && (
									<Flex style={{
										padding: item.link_2.padding ?? 32,
										position: "relative"
									}} className="slide-container__content--item" flex={item.link_2.flex} justify={item.link_2.justify ?? "center"} align={item.link_2.align ?? "center"}>
										<img style={{ borderRadius: 16 }} src={item.link_2.url} className={cn({
											"image-cover": !item.link_2.isContain,
											"image-contain": item.link_2.isContain
										})} alt="video"/>
										<Flex align="center" justify="center" style={{ position: "absolute", left: 0, top: 0, width: "100%", height: "100%" }}>
											{item.link_2.Action}
										</Flex>
									</Flex>
								)}
							</Flex>
						</Flex>
					</Flex>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

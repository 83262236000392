import { FC } from "react";
import { VerticalSwiperItem } from "@/features/landing-vertical-swiper";
import { UseCaseTemplate } from "@/entities/use-cases";

import line from "@/assets/use-cases/script/line.svg";
import scriptPreview from "@/assets/use-cases/script/script-preview.svg";
import slide1 from "@/assets/use-cases/script/slide-1.svg";
import slide2 from "@/assets/use-cases/script/slide-2.svg";
import slide3 from "@/assets/use-cases/script/slide-3.svg";
import slide4 from "@/assets/use-cases/script/slide-4.svg";
import slide5 from "@/assets/use-cases/script/slide-5.svg";
import slide6 from "@/assets/use-cases/script/slide-6.svg";
import slide7 from "@/assets/use-cases/script/slide-7.svg";
import slide8 from "@/assets/use-cases/script/slide-8.svg";

export const UseCaseScriptNew: FC = () => {
	const items: VerticalSwiperItem[] = [
		{
			title: "Script assistant",
			description: "Upload your screenplay to Blooper and instantly receive a script breakdown. No script yet? - No problem. Simply input your project briefing, and Blooper will generate a draft for you in minutes.",
			link_1: {
				url: slide1,
				flex: 2,
				isContain: true
			},
			link_2: {
				url: slide2,
				flex: 3,
				isContain: true
			}
		},
		{
			title: "Script breakdown",
			description: "Define the shots of your movie by hand or let Blooper do it - you decide. Our AI-driven assistant analyzes your script and proposes a shot outline.",
			link_1: {
				url: slide3,
				flex: 1,
				padding: "32px 0 32px 32px",
				isContain: true
			},
			link_2: {
				url: slide4,
				flex: 1,
				padding: "32px 0 32px 32px",
				isContain: true
			}
		},
		{
			title: "Character creation",
			description: "Our AI assistant recognizes characters in your script and provides you with a selection of people to embody them in your storyboard.",
			link_1: {
				url: slide5,
				flex: 2,
				isContain: true
			},
			link_2: {
				url: slide6,
				flex: 3,
				isContain: true
			}
		},
		{
			title: "Collaboration tool",
			description: "Share and discuss ideas with your co-workers directly within the script, making collaboration easier than ever before.",
			link_1: {
				url: slide7,
				flex: 3,
				isContain: true
			},
			link_2: {
				url: slide8,
				flex: 2,
				isContain: true
			}
		}
	];

	return (
		<UseCaseTemplate
			isComingSoon={false}
			items={items}
			description="Streamline your workflow with AI-powered script breakdowns and shot outlines, turning your projects into reality with precision and speed."
			italicHeaderText="effortless"
			headerText="Script breakdowns made"
			lineImg={line}
			previewImg={scriptPreview}
			lineClassName="use-case-template__line--right"
		/>
	);
};

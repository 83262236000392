import { Button, Typography } from "antd";
import { FC } from "react";
import ReactGA from "react-ga4";
import { VerticalSwiperItem } from "@/features/landing-vertical-swiper";
import { UseCaseTemplate } from "@/entities/use-cases";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import blooperExamplePDF from "@/assets/blooper-example.pdf";
import line from "@/assets/use-cases/storyboard/line.svg";
import pdfDownloadIcon from "@/assets/use-cases/storyboard/pdf-download-icon.svg";
import storyboardPreview from "@/assets/use-cases/storyboard/preview.svg";
import slide1 from "@/assets/use-cases/storyboard/slide-1.svg";
import slide2 from "@/assets/use-cases/storyboard/slide-2.svg";
import slide3 from "@/assets/use-cases/storyboard/slide-3.svg";
import slide4 from "@/assets/use-cases/storyboard/slide-4.svg";
import slide5 from "@/assets/use-cases/storyboard/slide-5.svg";
import slide6 from "@/assets/use-cases/storyboard/slide-6.svg";
import slide7 from "@/assets/use-cases/storyboard/slide-7.svg";

const DownloadPdf: FC = () => {
	const { isMobileL } = useMediaQueries();
	const onButtonClick = () => {
		const link = document.createElement("a");
		link.href = blooperExamplePDF;
		link.download = "Blooper example.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		ReactGA.event({
			category: "pdf download",
			action: "download pdf from use case storyboard"
		});
	};

	return (
		<Button style={{
			position: "absolute",
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)"
		}} onClick={onButtonClick} className="flex-ctr-ctr gap-4" type="primary">
			{!isMobileL && <img src={pdfDownloadIcon} alt="download" />}
			<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>View example PDF</Typography.Text>
		</Button>
	);
};

export const UseCaseStoryboardNew: FC = () => {
	const items: VerticalSwiperItem[] = [
		{
			title: "Moodboard creation",
			description: "Simplify the process of gathering and organizing visual inspiration. Our tool helps you create a moodboard tailored to your script, customized to match your unique style.",
			link_1: {
				url: slide1,
				flex: 2,
				padding: "32px 32px 0 32px",
				isContain: true
			},
			link_2: {
				url: slide2,
				flex: 1,
				isContain: true
			}
		},
		{
			title: "Storyboard generation",
			description: "Go from shot outline to storyboard in seconds. Accelerate your pre-production with an AI-generated first draft, then refine it from there. Blooper also ensures seamless transitions between shots, keeping your vision intact.",
			link_1: {
				url: slide3,
				flex: 1,
				isContain: true
			},
			link_2: {
				url: slide4,
				flex: 1,
				isContain: true
			}
		},
		{
			title: "Composition settings",
			description: "Our image generation tool is tailored to the needs of the movie industry. Generate storyboard panels based on textual descriptions, shot compositions and camera angles. With constant possibilities \n" +
				"for manual editing at any step of generation.",
			comingSoon: true,
			link_1: {
				url: slide5,
				flex: 1,
				padding: "0 32px 32px 32px",
				isContain: true
			},
			link_2: {
				url: slide6,
				flex: 1,
				padding: "0 32px 32px 32px",
				isContain: true
			}
		},
		{
			title: "Export to PDF and other file types",
			description: "Simply integrate with your existing workflow by exporting to various file formats, including PDFs. With Blooper's versatile export capabilities, effortlessly transition between platforms and collaborate with ease.",
			link_1: {
				url: slide7,
				flex: 1,
				padding: "32px 32px 0 32px",
				isContain: true,
				Action: <DownloadPdf />
			},
			link_2: {
				url: "",
				flex: 0,
				isContain: true
			}
		}
	];

	return (
		<UseCaseTemplate
			isComingSoon={false}
			items={items}
			description="	Bringing your film’s scenes and sequences to life is crucial for powerful storytelling and precise planning. With Blooper, directors and producers can seamlessly transform their artistic vision into reality."
			italicHeaderText="with AI"
			headerText="Convert Scripts to Storyboards Instantly"
			lineImg={line}
			previewImg={storyboardPreview}
			lineClassName="use-case-template__line--left"
		/>
	);
};

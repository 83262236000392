import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SubscribeCustomer, subscribeCustomerResource } from "@/shared/api/payment";

export const useBehaviour = () => {
	const navigate = useNavigate();
	const [subscribeLoading, setSubscribeLoading] = useState(false);

	const subscribe = async (params: SubscribeCustomer) => {
		try {
			setSubscribeLoading(true);
			await subscribeCustomerResource(params);

			// if (subscription?.message === "Already subscribed") {
			// 	const { data: credentials } = await getPaymentCredentialsResource(params.token);
			// 	if (credentials.message === "Subscription was voided, please renew") {
			// 		navigate(`/payment/${params.productId}/${params.interval}`);
			// 	} else {
			// 		navigate("/already-subscribed");
			// 	}
			// }
			navigate(`/payment/${params.productId}/${params.interval}`);
		} catch (e) {
			console.error(e);
		} finally {
			setSubscribeLoading(false);
		}
	};

	return {
		subscribeLoading,
		subscribe
	};
};

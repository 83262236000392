import { AxiosResponse } from "axios";
import { api } from "@/shared/api";

import {
	GenerateImageResult,
	GenerateShotParams,
	GenerateShotResult,
	GetShotParams,
	GetShotResult,
	RegenerateImageParams,
	ShotImage,
	TStoryboardResponse
} from "./types";
import { TResponse } from "@/vite-env";

export const getStoryboardResource = (projectId: string, controller: AbortController): Promise<AxiosResponse<TStoryboardResponse>> =>
	api.get(`/storyboards/${projectId}/full/`, { signal: controller.signal });

export const getStoryboardShotResource = (params: GetShotParams, controller: AbortController): TResponse<GetShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`, { signal: controller.signal });

export const generateStoryboardShotResource = (params: GenerateShotParams): TResponse<GenerateShotResult> =>
	api.get(`/storyboards/${params.projectId}/shots/${params.shotId}`);

export const regenerateImageResource = (params: RegenerateImageParams, controller: AbortController): TResponse<GenerateImageResult> =>
	api.patch(`/storyboards/${params.projectId}/shots/${params.shotId}`, {}, { signal: controller.signal });

export const getProjectsStoryboardImagesResource = (name: string): Promise<AxiosResponse<{ [id: string]: ShotImage }>> =>
	api.get(`/storyboards/${name}/scenes/shots/images`);

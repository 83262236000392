import { useAtom } from "@reatom/npm-react";
import * as Sentry from "@sentry/react";
import { ComponentType, FC, useEffect } from "react";
import ReactGA from "react-ga4";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { createBrowserRouter, Navigate, useLocation, useRouteError } from "react-router-dom";

import { BlooperExample } from "@/pages/blooper-example";
import { Character } from "@/pages/character";
import { CharacterInfo } from "@/pages/character-info";
import { Contacts } from "@/pages/contacts";
import { CreateNewPassword } from "@/pages/create-new-password";
import { AcceptableUsePolicy, CookiePolicy, PrivacyPolicy, TermsConditions } from "@/pages/documents";
import { EnterTheCode } from "@/pages/enter-the-code";
import { DesktopOnly, Error404, Error500 } from "@/pages/errors";
import { Invitation } from "@/pages/invitation";
import { LandingTeam } from "@/pages/landing-team";
import { Login } from "@/pages/login";
import { Moodboard } from "@/pages/moodboard";
import { Payment } from "@/pages/payment";
import { AlreadySubscribed, PaymentSuccess } from "@/pages/payment-success";
import { Plan, PromoCode } from "@/pages/plan";
import { Pricing } from "@/pages/pricing";
import { ProductNew } from "@/pages/product";
import { Projects } from "@/pages/projects";
import { RecoverPassword } from "@/pages/recover-password";
import { Register } from "@/pages/register";
import { Script } from "@/pages/script";
import {
	SettingsAccount,
	SettingsBilling,
	SettingsPreferences,
	SettingsProfile,
	SettingsTeam
} from "@/pages/settings";
import { ShotEdit } from "@/pages/shot-edit";
import { Storyboard } from "@/pages/storyboard";
import { SuccessPasswordChanged } from "@/pages/success-password-changed";
import { TeamManage } from "@/pages/team-manage";
import { UseCaseScriptNew } from "@/pages/use-case-script";
import { UseCaseStoryboardNew } from "@/pages/use-case-storyboard";
import { UseCaseVideoNew } from "@/pages/use-case-video";
import { SuccessEmail, Verify } from "@/pages/verification";

import { HomeLeftSidebar } from "@/widgets/home-left-sidebar";
import { LandingLayout } from "@/widgets/landing-layout";
import { SettingsLayout } from "@/widgets/settings-layout";
import { StoryboardSidebar } from "@/widgets/storyboard-sidebar";
import { ErrorWrapper, SuccessWrapper } from "@/entities/notification/ui";
import { Figure, figureAtom, isBubbleAtom } from "@/entities/use-cases";
import { getCredential, getRegisterCredential } from "@/entities/viewer/lib/utils";
import { NOT_RELEASE } from "@/shared/const/release.ts";
import { mobileCheck } from "@/shared/methods";
import { CookieBanner, DesktopAccess } from "@/shared/ui";

import scriptFigure from "@/assets/use-cases/script/figure.svg";
import storyboardFigure from "@/assets/use-cases/storyboard/figure.svg";
import videoFigure from "@/assets/use-cases/video/figure.svg";

const IMAGE_FIGURE: Record<string, Figure> = {
	"/use-case-script": {
		url: scriptFigure,
		position: "left"
	},
	"/use-case-storyboard": {
		url: storyboardFigure,
		position: "right"
	},
	"/use-case-video": {
		url: videoFigure,
		position: "left"
	}
};

interface IRoute {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: ComponentType<any>;
}

const AuthRoute: FC<IRoute> = ({ component: Component }) => {
	const viewer = getCredential()?.data;
	const isAuth = !!viewer?.key;

	if (isAuth) {
		return <Navigate to="/main/projects" />;
	}

	return (
		<ErrorWrapper>
			<Component />
			<CookieBanner />
		</ErrorWrapper>
	)
	;
};

const PrivateLoginRoute: FC<IRoute> = ({ component: Component }) => {
	const viewer = getCredential()?.data;
	const isAuth = !!viewer?.key;

	if (!isAuth) {
		return <Navigate to="/login" />;
	}

	return (
		<ErrorWrapper>
			<SuccessWrapper>
				<Component />
				<CookieBanner />
			</SuccessWrapper>
		</ErrorWrapper>
	);
};

const PrivateRoute: FC<IRoute> = ({ component: Component }) => {
	const { setPathPageView, setTrackPageView } = useTrackingCode();
	const { pathname } = useLocation();
	const viewer = getCredential()?.data;
	const isAuth = !!viewer?.key;
	const isMobile = mobileCheck();

	useEffect(() => {
		if (isAuth) {
			setPathPageView(pathname);
			setTrackPageView();
		}
	}, []);

	if (isMobile && !NOT_RELEASE) {
		return <Navigate to="/desktop-only" />;
	}

	if (!isAuth) {
		return <Navigate to="/login" />;
	}

	return (
		<ErrorWrapper>
			<SuccessWrapper>
				<Component />
				<CookieBanner />
			</SuccessWrapper>
		</ErrorWrapper>
	);
};

export const PublicRoute: FC<IRoute> = ({ component: Component }) => {
	const { pathname } = useLocation();
	const [_, setBubble] = useAtom(isBubbleAtom);
	const [__, setFigure] = useAtom(figureAtom);

	useEffect(() => {
		setBubble(pathname === "/");
		setFigure(IMAGE_FIGURE[pathname]);
		ReactGA.send({ hitType: "pageview", page: pathname });
	}, [pathname]);

	return (
		<>
			<Component />
			<CookieBanner />
			<DesktopAccess />
		</>
	);
};

export const PaymentRoute: FC<IRoute> = ({ component: Component }) => {
	const viewer = getRegisterCredential()?.data;
	const isAuth = !!viewer?.key;

	if (!isAuth) {
		return <Navigate to="/login" />;
	}

	return (
		<ErrorWrapper>
			<Component />
		</ErrorWrapper>
	);
};

function ErrorBoundary () {
	const error = useRouteError();
	console.error(error);
	// Uncaught ReferenceError: path is not defined
	return <Error500 />;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
	createBrowserRouter
);

export const router = sentryCreateBrowserRouter([
	{
		path: "/",
		element: <LandingLayout />,
		children: [
			{
				path: "",
				element: <PublicRoute component={ProductNew} />
			},
			{
				path: "blooper-example",
				element: <PublicRoute component={BlooperExample} />
			},
			{
				path: "use-case-video",
				element: <PublicRoute component={UseCaseVideoNew} />
			},
			{
				path: "use-case-storyboard",
				element: <PublicRoute component={UseCaseStoryboardNew} />
			},
			{
				path: "use-case-script",
				element: <PublicRoute component={UseCaseScriptNew} />
			},
			{
				path: "contacts",
				element: <PublicRoute component={Contacts} />
			},
			{
				path: "team",
				element: <PublicRoute component={LandingTeam} />
			},
			{
				path: "pricing",
				element: <PublicRoute component={Pricing} />
			}
		]
	},
	{
		path: "/storyboard/:id",
		element: <PrivateRoute component={StoryboardSidebar} />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: "script",
				element: <Script />
			},
			{
				path: "moodboard",
				element: <Moodboard />
			},
			{
				path: "character",
				element: <Character />
			},
			{
				path: "character/:characterId/character-info",
				element: <CharacterInfo />
			},
			{
				path: "storyboard",
				element: <Storyboard />
			},
			{
				path: "shot-selection/:sceneId/:shotId",
				element: <ShotEdit />
			}
		]
	},
	{
		path: "/main",
		element: <HomeLeftSidebar />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: "team-manage",
				element: <PrivateRoute component={TeamManage} />
			},
			{
				path: "projects",
				element: <PrivateLoginRoute component={Projects} />
			},
			{
				path: "settings",
				element: <PrivateRoute component={SettingsLayout} />,
				children: [
					{
						path: "profile",
						element: <SettingsProfile />
					},
					{
						path: "account",
						element: <SettingsAccount />
					},
					{
						path: "members",
						element: <SettingsTeam />
					},
					{
						path: "preferences",
						element: <SettingsPreferences />
					},
					{
						path: "billing",
						element: <SettingsBilling />
					}
				]
			},
			{
				path: "404",
				element: <Error404 />
			},
			{
				path: "500",
				element: <Error500 />
			}
		]
	},
	{
		path: "/login",
		element: <AuthRoute component={Login} />
	},
	{
		path: "/register",
		element: <AuthRoute component={Register} />
	},
	{
		path: "/success-email/:email",
		element: <AuthRoute component={SuccessEmail} />
	},
	{
		path: "/plan",
		element: <PaymentRoute component={Plan} />
	},
	{
		path: "/promo-code/:interval/:productId",
		element: <PaymentRoute component={PromoCode} />
	},
	{
		path: "/payment-success",
		element: <PaymentRoute component={PaymentSuccess} />
	},
	{
		path: "/already-subscribed",
		element: <PaymentRoute component={AlreadySubscribed} />
	},
	{
		path: "/payment/:termType/:interval",
		element: <PaymentRoute component={Payment} />
	},
	{
		path: "/auth/verify",
		element: <AuthRoute component={Verify} />
	},
	{
		path: "/auth/verify/invitation",
		element: <Invitation />
	},
	{
		path: "/recover-password",
		element: <AuthRoute component={RecoverPassword} />
	},
	{
		// TODO fix mobile version
		path: "/enter-the-code/:email",
		element: <AuthRoute component={EnterTheCode} />
	},
	{
		path: "/create-new-password/:email",
		element: <AuthRoute component={CreateNewPassword} />
	},
	{
		path: "/password-success-changed",
		element: <AuthRoute component={SuccessPasswordChanged} />
	},
	{
		path: "/404",
		element: <PublicRoute component={Error404} />
	},
	{
		path: "/500",
		element: <PublicRoute component={Error500} />
	},
	{
		path: "/desktop-only",
		element: <PublicRoute component={DesktopOnly} />
	},
	{
		path: "/privacy-policy",
		element: <PublicRoute component={PrivacyPolicy} />
	},
	{
		path: "/terms-of-conditions",
		element: <PublicRoute component={TermsConditions} />
	},
	{
		path: "/cookie-policy",
		element: <PublicRoute component={CookiePolicy} />
	},
	{
		path: "/acceptable-use-policy",
		element: <PublicRoute component={AcceptableUsePolicy} />
	},
	{
		path: "*",
		element: <Error404 />
	}
]);

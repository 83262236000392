import { useAction } from "@reatom/npm-react";
import {
	useElements,
	useStripe,
	PaymentElement
} from "@stripe/react-stripe-js";
import { Button, Flex, Typography } from "antd";
import cn from "classnames";
import { FC, FormEvent, useState } from "react";
import TagManager from "react-gtm-module";
import { useNavigate } from "react-router-dom";
import { callErrorAction } from "@/entities/notification";
import { logoutAction } from "@/entities/viewer";
import stripeImg from "@/assets/shared/stripe.svg";
import { COLOR_CLASSNAMES, COLOR_NAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";

import "./PaymentDetails.scss";

export const StripePaymentDetails: FC = () => {
	const stripe = useStripe();
	const navigate = useNavigate();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false);
	const catchError = useAction(callErrorAction);
	const { isMobileL } = useMediaQueries();
	const logout = useAction(logoutAction);

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setIsLoading(true);

		if (!stripe || !elements) {
			setIsLoading(false);
			return;
		}

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/payment-success`
			},
			redirect: "if_required"
		});

		if (result.error) {
			if (result.error.code === "payment_intent_unexpected_state") {
				navigate("/already-subscribed");
			} else {
				console.error(result.error);
				catchError(result.error, true);
			}
		} else {
			TagManager.dataLayer({
				dataLayer: {
					event: "subscription",
					subscriptionAmount: result.paymentIntent.amount
				}
			});
			navigate("/payment-success");
			// Your customer will be redirected to your `return_url`. For some payment
			// methods like iDEAL, your customer will be redirected to an intermediate
			// site first to authorize the payment, then redirected to the `return_url`.
		}
		setIsLoading(false);
	};

	return (
		<form style={{ maxWidth: isMobileL ? 370 : 462 }} className="full-width" onSubmit={handleSubmit}>
			<PaymentElement
				className="payment-details"
			/>
			<Flex vertical gap={16}>
				<Button
					htmlType="submit"
					className="full-width payment-details__button"
					type="primary"
					disabled={isLoading}
					loading={isLoading}
				>
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Start subscription</Typography.Text>
				</Button>
				<Button onClick={logout} className="full-width">
					<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
				</Button>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextSecondary)}>
					By selecting Start subscription, you agree that Stripe may store your <br/> payment method for your future use in accordance with its <br/>
					<a target="_blank" href="/privacy-policy" style={{ color: COLOR_NAMES.Green600 }}>Privacy Policy. You can opt-out at any time. Learn more.</a>
				</Typography.Text>
				<Flex justify="center" align="center" gap={8}>
					<img src={stripeImg} alt="Powered by Stripe" />
					<div style={{ width: 1, height: 16, background: COLOR_NAMES.TextTertiary }} />
					<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}><a style={{ color: COLOR_NAMES.TextTertiary }} href="https://stripe.com/en-lv/privacy" target="_blank">Privacy policy</a></Typography.Text>
				</Flex>
			</Flex>
		</form>
	);
};

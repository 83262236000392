import { useAction } from "@reatom/npm-react";
import { Button, Flex, Modal, Typography } from "antd";
import { FC } from "react";
import { useParams } from "react-router-dom";

import { deleteShotAction } from "@/entities/script";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";

import { IOpenModal } from "@/shared/hooks";
import info from "@/assets/description/info.svg";

interface IDeleteSceneModal extends IOpenModal{
	sceneId: string;
	shotId: string;
}

export const DeleteShotModal: FC<IDeleteSceneModal> = ({ sceneId, shotId, toggleModal, isOpen }) => {
	const { id } = useParams();
	const deleteShot = useAction(deleteShotAction);
	const handleDelete = () => {
		if (id) {
			deleteShot(id, { sceneId, shotId });
		}
	};

	return (
		<>
			<Modal
				open={isOpen}
				width={590}
				onCancel={() => toggleModal(false)}
				footer={
					<Flex gap={8} justify="flex-end">
						<Button onClick={() => toggleModal(false)}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Cancel</Typography.Text>
						</Button>
						<Button type="primary" onClick={handleDelete}>
							<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>Yes, delete it</Typography.Text>
						</Button>
					</Flex>
				}
			>
				<Flex vertical className="gap-xxs">
					<Flex style={{ background: "transparent" }} align="center" className="gap-xxs">
						<img src={info} alt="info" />
						<Typography.Text>Are you sure you want to delete the shot?</Typography.Text>
					</Flex>
					<Typography.Text style={{ paddingLeft: 40 }} className={TEXT_CLASSNAMES.XsRegular}>
						All content from this shot will be deleted
					</Typography.Text>
				</Flex>
			</Modal>
		</>
	);
};

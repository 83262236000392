import { reatomAsync, withAbort, withStatusesAtom } from "@reatom/async";
import { action, atom } from "@reatom/core";
import { UploadChangeParam } from "antd/es/upload/interface";
import { AxiosError } from "axios";
import { Descendant } from "slate";
import { v4 as uuidv4 } from "uuid";

import { Nullable } from "vitest";
import { callErrorAction } from "@/entities/notification";
import {
	createSceneResource,
	createShotResource,
	deleteSceneResource,
	deleteShotResource,
	getHighlightTheShotsResource,
	TSceneInfo,
	TShotColor,
	TShotInfo,
	updateSceneResource,
	updateShotResource,
	uploadScriptResource
} from "@/shared/api/highlight-the-shots";
import { ProjectLocation } from "@/shared/api/project";
import { insert } from "@/shared/methods";
import {
	TShotDTO,
	TScene,
	TShot,
	TUpdateShotDTO,
	TUpdateShotColorDTO,
	TUpdateShotTitleDTO,
	SceneHistory,
	HistoryRequest,
	sortScenes,
	insertShots,
	randomColor,
	split,
	HistoryData,
	SceneHistoryRequest,
	SceneTitleDTO, getScriptLength
} from "../lib";

export const sceneListAtom = atom<TScene[]>([], "scenesAtom");

sceneListAtom.onChange((ctx, newState) => {
	const length = getScriptLength(newState);

	scriptSignsLengthAtom(ctx, length);
});

export const scriptSignsLengthAtom = atom<number>(0, "scriptSignsLengthAtom");

export const historySceneListAtom = atom<SceneHistory>({
	past: [],
	future: []
}, "historySceneListAtom");

export const updateHistoryValue = action((ctx, val: TScene[], type: HistoryRequest, data: HistoryData) => {
	const sceneListHistory = ctx.get(historySceneListAtom);
	const currentSceneList = ctx.get(sceneListAtom);

	const currentListHistory: SceneHistoryRequest = { history: currentSceneList, request: type, data };

	const _past = [...sceneListHistory.past, currentListHistory];
	const maxLengthNum = 10;
	// maximum number of records exceeded
	if (maxLengthNum > 0 && _past.length > maxLengthNum) {
		// delete first
		_past.splice(0, 1);
	}

	historySceneListAtom(ctx, {
		past: _past,
		future: []
	});

	sceneListAtom(ctx, val);
});

const forward = action(async (ctx, step: number = 1, projectId: string) => {
	try {
		const sceneListHistory = ctx.get(historySceneListAtom);
		const currentSceneList = ctx.get(sceneListAtom);

		if (sceneListHistory.future.length === 0) {
			return;
		}

		const { _before, _current, _after } = split(step, sceneListHistory.future);

		const currentListHistory: SceneHistoryRequest = { history: currentSceneList, request: _current.request, data: _current.data };

		historySceneListAtom(ctx, {
			past: [...sceneListHistory.past, currentListHistory, ..._before],
			future: _after
		});
		sceneListAtom(ctx, _current.history);

		await historyRequests(ctx, _current, projectId);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

const backward = action(async (ctx, step: number = -1, projectId: string) => {
	try {
		const sceneListHistory = ctx.get(historySceneListAtom);
		const currentSceneList = ctx.get(sceneListAtom);

		if (sceneListHistory.past.length === 0) {
			return;
		}

		const { _before, _current, _after } = split(step, sceneListHistory.past);

		const currentListHistory: SceneHistoryRequest = { history: currentSceneList, request: _current.request, data: _current.data };

		if (_current.history.length) {
			historySceneListAtom(ctx, {
				past: _before,
				future: [..._after, currentListHistory, ...sceneListHistory.future]
			});

			sceneListAtom(ctx, _current.history);
		}

		await historyRequests(ctx, _current, projectId);
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

const historyRequests = action(async (ctx, _current: SceneHistoryRequest, projectId: string) => {
	try {
		const { shotId, sceneId } = _current.data;
		const currentScene = _current.history.find((scene) => scene.id === sceneId);
		const currentShot = currentScene?.shots.find((shot) => shot.id === shotId);

		const shotInfo: TShotInfo = {
			id: shotId,
			scene_id: sceneId,
			title: currentShot?.title ?? "",
			prompt: currentShot?.prompt ?? [],
			color: currentShot?.color ?? "red",
			description: currentShot?.description ?? ""
		};

		const sceneInfo: TSceneInfo = {
			scene_id: sceneId,
			title: currentScene?.title ?? "",
			shots_order: currentScene?.shots.map((shot) => shot.id) || [],
			scene_locations: currentScene?.locations || [],
			selected_location: currentScene?.selectedLocation ?? ""
		};

		const scenesOrder: string[] = _current.history.map((scene) => scene.id);
		const shotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

		if (_current.request === "updateShotResource") {
			await updateShotResource(projectId, { shots_order: shotsOrder, shot_info: shotInfo });
		}

		if (_current.request === "deleteShotResource") {
			await createShotResource(projectId, { shots_order: shotsOrder, shot_info: shotInfo });
		}

		if (_current.request === "createShotResource") {
			await deleteShotResource(projectId, { shots_order: shotsOrder, shot_info: shotInfo });
		}

		if (_current.request === "deleteSceneResource") {
			await createSceneResource(projectId, { scene_info: sceneInfo, scenes_order: scenesOrder });
			const currentShotsOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];
			const shotPromises = currentScene?.shots.map(async (shot) => {
				const currentShotInfo: TShotInfo = {
					id: shot.id,
					scene_id: sceneId,
					title: shot.title,
					prompt: shot.prompt,
					color: shot.color,
					description: shot.description
				};

				await createShotResource(projectId, { shots_order: currentShotsOrder, shot_info: currentShotInfo });
			});

			await Promise.all(shotPromises || []);
		}

		if (_current.request === "createSceneResource") {
			await deleteSceneResource(projectId, { scene_info: sceneInfo, scenes_order: scenesOrder });
		}

		if (_current.request === "updateSceneResource") {
			await updateSceneResource(projectId, { scene_info: sceneInfo, scenes_order: scenesOrder });
		}
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

const go = action((ctx, step: number, projectId: string) => {
	if (step === 0) {
		return;
	}

	if (step > 0) {
		return forward(ctx, step, projectId);
	}

	backward(ctx, step, projectId);
});

export const backAction = action((ctx, projectId: string) => go(ctx, -1, projectId));
export const forwardAction = action((ctx, projectId: string) => go(ctx, 1, projectId));

export const descriptionStatusAtom = atom<"empty" | "full">((ctx) => {
	const sceneList = ctx.spy(sceneListAtom);

	if (!sceneList.length) {
		return "empty";
	}

	return "full";
}, "isDescriptionEmptyAtom");

export const getSceneListAction = reatomAsync(async (ctx, projectId: string) => {
	const { data } = await getHighlightTheShotsResource(projectId, ctx.controller);

	return data;
}, {
	onFulfill: (ctx, response) => {
		const orders = response.scenes_order;
		const scenes = response.scenes_info;
		const sceneList = sortScenes(scenes, orders);

		updateHistoryValue(ctx, sceneList, "getHighlightTheShotsResource", { sceneId: "", shotId: "" });
	},
	onReject: (ctx, err) => {
		callErrorAction(ctx, err as AxiosError);
	}
}).pipe(withAbort(), withStatusesAtom());

export const addSceneAction = action(async (ctx, projectId: string, location: ProjectLocation) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const sceneTitle = `Scene ${sceneList.length + 1}`;
		const sceneId = uuidv4();
		const locations = (location.country && location.city) ? [`${location.country} ${location.city}`] : [];
		const selectedLocation = (location.country && location.city) ? `${location.country} ${location.city}` : "";

		const sceneInfo: TSceneInfo = {
			scene_id: sceneId,
			shots_order: [],
			title: sceneTitle,
			scene_locations: locations,
			selected_location: selectedLocation
		};

		const sceneOrder: string[] = [...sceneList.map((currentScene) => currentScene.id), sceneId];

		const scene: TScene = {
			id: sceneId,
			title: sceneTitle,
			idx: sceneList.length,
			shots: [],
			locations,
			selectedLocation
		};

		updateHistoryValue(ctx, [...sceneList, scene], "createSceneResource", { sceneId, shotId: "" });

		await createSceneResource(projectId, { scene_info: sceneInfo, scenes_order: sceneOrder });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const generateAction = action(async (ctx, projectId: string) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const sceneTitle = `Scene ${sceneList.length + 1}`;
		const sceneId = uuidv4();

		const sceneInfo: TSceneInfo = {
			scene_id: sceneId,
			shots_order: [],
			title: sceneTitle,
			scene_locations: [],
			selected_location: ""
		};

		const sceneOrder: string[] = [...sceneList.map((currentScene) => currentScene.id), sceneId];

		const scene: TScene = {
			id: sceneId,
			title: sceneTitle,
			idx: sceneList.length,
			shots: [],
			locations: [],
			selectedLocation: ""
		};
		updateHistoryValue(ctx, [...sceneList, scene], "createSceneResource", { sceneId, shotId: "" });

		await createSceneResource(projectId, { scene_info: sceneInfo, scenes_order: sceneOrder });
		await addShotToSceneAction(ctx, projectId, { sceneId, shotId: "" });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const addShotToSceneAction = action(async (ctx, projectId: string, params: TShotDTO) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
		const lastShotIndex = currentScene?.shots.findIndex((shot) => shot.id === params.shotId) || 0;
		const shotIndex = currentScene?.shots.length || 1;

		const shotId = uuidv4();
		const shotTitle = "";
		const shotColor = randomColor.next().value as TShotColor;
		const shotDescription = "";

		const shotPrompt = [{
			type: "paragraph",
			children: [{ text: "" }]
		}] as Descendant[];

		const shotInfo: TShotInfo = {
			id: shotId,
			scene_id: params.sceneId,
			title: shotTitle,
			prompt: shotPrompt,
			color: shotColor,
			description: shotDescription
		};

		const currentOrderList = currentScene?.shots.map((shot) => shot.id) || [];

		const shotOrder: string[] = currentScene ? insert<string>(lastShotIndex + 1, [shotId], currentOrderList) : [shotId];

		const shot: TShot = {
			id: shotId,
			idx: shotIndex,
			title: shotTitle,
			prompt: shotPrompt,
			color: shotColor,
			description: shotDescription
		};

		const updatedScene: TScene[] = sceneList.map((scene) => {
			if (scene.id === params.sceneId) {
				const params = {
					shot,
					lastShotIndex,
					shots: scene.shots
				};

				const updatedShots = insertShots(params, shotOrder);

				return {
					...scene,
					shots: updatedShots
				};
			}

			return {
				...scene
			};
		});

		updateHistoryValue(ctx, updatedScene, "createShotResource", { sceneId: params.sceneId, shotId });

		await createShotResource(projectId, { shots_order: shotOrder, shot_info: shotInfo });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const deleteShotAction = action(async (ctx, projectId: string, params: TShotDTO) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const scene = sceneList.find((scene) => scene.id === params.sceneId);
		const deletedShot = scene?.shots.find((shot) => shot.id === params.shotId);

		if (deletedShot) {
			const shotInfo: TShotInfo = {
				id: deletedShot.id,
				scene_id: params.sceneId,
				title: deletedShot.title,
				prompt: deletedShot.prompt,
				color: deletedShot.color,
				description: deletedShot.description
			};
			const shotsOrder: string[] = scene?.shots.map((shot) => shot.id)?.filter((shotId) => shotId !== params.shotId) || [];

			const filterShots = (shots: TShot[]) => {
				const filteredShots = shots.filter((shot) => shot.id !== params.shotId);

				return filteredShots.map((shot, idx) => ({
					...shot,
					idx
				}));
			};

			const updatedSceneList = sceneList.map((scene) => ({
				...scene,
				shots: filterShots(scene.shots)
			}));

			updateHistoryValue(ctx, updatedSceneList, "deleteShotResource", { sceneId: params.sceneId, shotId: deletedShot.id });

			await deleteShotResource(projectId, { shot_info: shotInfo, shots_order: shotsOrder });
		}
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const updateShotValueInSceneAction = action(async (ctx, projectId: string, params: TUpdateShotDTO) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
		const currentShot = currentScene?.shots.find((shot) => shot.id === params.shotId);

		const shotsInfo: TShotInfo = {
			id: currentShot?.id ?? "",
			scene_id: params.sceneId,
			title: currentShot?.title ?? "",
			prompt: params.value,
			color: currentShot?.color ?? "red",
			description: params.description
		};

		const shotOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

		const updatedScene: TScene[] = sceneList.map((scene) => {
			if (scene.id === params.sceneId) {
				return {
					...scene,
					shots: scene.shots.map((shot) => ({
						...shot,
						prompt: shot.id === params.shotId ? params.value : shot.prompt,
						description: shot.id === params.shotId ? params.description : shot.description
					}))
				};
			}

			return {
				...scene
			};
		});

		updateHistoryValue(ctx, updatedScene, "updateShotResource", { sceneId: params.sceneId, shotId: params.shotId });
		await updateShotResource(projectId, { shots_order: shotOrder, shot_info: shotsInfo });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const updateShotTitleInSceneAction = action(async (ctx, projectId: string, params: TUpdateShotTitleDTO) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
		const currentShot = currentScene?.shots.find((shot) => shot.id === params.shotId);

		const shotsInfo: TShotInfo = {
			id: currentShot?.id ?? "",
			scene_id: params.sceneId,
			title: params.title,
			prompt: currentShot?.prompt ?? [],
			color: currentShot?.color ?? "red",
			description: currentShot?.description ?? ""
		};

		const shotOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

		const updatedScene: TScene[] = sceneList.map((scene) => {
			if (scene.id === params.sceneId) {
				return {
					...scene,
					shots: scene.shots.map((shot) => ({
						...shot,
						title: shot.id === params.shotId ? params.title : shot.title
					}))
				};
			}

			return {
				...scene
			};
		});

		updateHistoryValue(ctx, updatedScene, "updateShotResource", { sceneId: params.sceneId, shotId: params.shotId });
		await updateShotResource(projectId, { shots_order: shotOrder, shot_info: shotsInfo });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const updateShotColorAction = action(async (ctx, projectId: string, params: TUpdateShotColorDTO) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const currentScene = sceneList.find((scene) => scene.id === params.sceneId);
		const currentShot = currentScene?.shots.find((shot) => shot.id === params.shotId);

		const shotsInfo: TShotInfo = {
			id: currentShot?.id ?? "",
			scene_id: params.sceneId,
			title: currentShot?.title ?? "",
			prompt: currentShot?.prompt ?? [],
			color: params.color ?? currentShot?.color ?? "red",
			description: currentShot?.description ?? ""
		};

		const shotOrder: string[] = currentScene?.shots.map((shot) => shot.id) || [];

		updateHistoryValue(ctx, sceneList.map((scene) => ({
			...scene,
			shots: scene.shots.map((shot) => ({
				...shot,
				color: params.shotId === shot.id ? params.color : shot.color
			}))
		})), "updateShotResource", { sceneId: params.sceneId, shotId: params.shotId });

		await updateShotResource(projectId, { shots_order: shotOrder, shot_info: shotsInfo });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const deleteSceneAction = action(async (ctx, projectId: string, sceneId: string) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const currentScene = sceneList.find((scene) => scene.id === sceneId);

		const scenesInfo: TSceneInfo = {
			scene_id: sceneId,
			title: currentScene?.title ?? "",
			shots_order: currentScene?.shots.map((shot) => shot.id) || [],
			scene_locations: currentScene?.locations || [],
			selected_location: currentScene?.selectedLocation ?? ""
		};

		const scenesOrder: string[] = sceneList.map((scene) => scene.id)?.filter((id) => id !== sceneId) || [];

		const filterScenes = () => {
			const updatedSceneList = sceneList.filter((scene) => scene.id !== sceneId);

			return updatedSceneList.map((scene, idx) => ({
				...scene,
				idx
			}));
		};

		updateHistoryValue(ctx, filterScenes(), "deleteSceneResource", { sceneId, shotId: "" });

		await deleteSceneResource(projectId, { scene_info: scenesInfo, scenes_order: scenesOrder });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

export const updateSceneTitleAction = action(async (ctx, projectId: string, params: SceneTitleDTO) => {
	try {
		const sceneList = ctx.get(sceneListAtom);
		const currentScene = sceneList.find((scene) => scene.id === params.sceneId);

		const sceneInfo: TSceneInfo = {
			scene_id: params.sceneId,
			title: params.title,
			shots_order: currentScene?.shots.map((shot) => shot.id) || [],
			scene_locations: currentScene?.locations || [],
			selected_location: currentScene?.selectedLocation ?? ""
		};

		const scenesOrder: string[] = sceneList.map((scene) => scene.id);

		updateHistoryValue(ctx, sceneList.map((scene) => ({
			...scene,
			title: scene.id === params.sceneId ? params.title : scene.title
		})), "updateSceneResource", { sceneId: params.sceneId, shotId: "" });

		await updateSceneResource(projectId, { scene_info: sceneInfo, scenes_order: scenesOrder });
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
}, "");

const cutSceneTitle = (title: string) => {
	let result = title;
	if (title[0] === "\"") {
		result = title.slice(1);
	}

	if (title[title.length - 1] === "\"") {
		result = result.slice(0, title.length - 2);
	}

	return result;
};

export const addScriptToProjectAction = action(async (ctx, script: Nullable<UploadChangeParam>, name: string | null) => {
	try {
		const file = script?.fileList[0];
		if (file?.originFileObj && name) {
			const formData = new FormData();
			formData.append("file", file.originFileObj);
			const getFileType = () => {
				if (file.type === "application/pdf") {
					return "pdf";
				}

				if (file.type === "text/plain") {
					return "txt";
				}

				if (file.type === "application/msword") {
					return "doc";
				}

				if (file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
					return "docx";
				}

				return "doc";
			};
			const response = await uploadScriptResource(name, formData, getFileType());
			const scenesOrder = response.data.scenes_order;
			const scenesInfo = response.data.scenes_info;

			console.log("==========>scenesInfo", scenesInfo);
			const scenePromises = Object.entries(scenesInfo).map(async ([sceneId, scene]) => {
				console.log("==========>scene", scene);
				const sceneInfo: TSceneInfo = {
					title: cutSceneTitle(scene.title),
					shots_order: scene.shots_order,
					scene_id: sceneId,
					scene_locations: scene.location_options,
					selected_location: scene.location_options?.[0] ?? ""
				};

				await createSceneResource(name, { scene_info: sceneInfo, scenes_order: scenesOrder });

				const shotsOrder = scene.shots_order;

				const shotsPromises = Object.entries(scene.shots_info).map(async ([shotId, shot]) => {
					const shotInfo: TShotInfo = {
						id: shotId,
						scene_id: sceneId,
						title: "",
						prompt: [{ type: "paragraph", children: [{ text: shot.description }] }],
						color: randomColor.next().value as TShotColor,
						description: shot.description
					};

					await createShotResource(name, { shots_order: shotsOrder, shot_info: shotInfo });
				});

				await Promise.all(shotsPromises);
			});

			await Promise.all(scenePromises);
		}
	} catch (err) {
		callErrorAction(ctx, err as AxiosError);
	}
});

import { useAction } from "@reatom/npm-react";
import { useKeyPress } from "ahooks";
import { Flex, Typography } from "antd";
import cn from "classnames";
import { FC, useEffect, useRef } from "react";

import { useParams } from "react-router-dom";
import { deleteShotAction, DeleteShotModal } from "@/entities/script";
import deleteImg from "@/assets/shared/delete.svg";
import { TShotColor } from "@/shared/api/highlight-the-shots";
import { COLOR_CLASSNAMES } from "@/shared/const/color-names";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames";
import { useOpenModal } from "@/shared/hooks";

import "./ShotTitle.scss";

type Props = {
	color: TShotColor;
	isHover: boolean;
	isActive: boolean;
	shotIdx: number;
	title: string;
	shotId: string;
	sceneId: string;
	isContent: boolean;
	sceneName: string;
}

export const ShotTitle: FC<Props> = ({ color, isHover, isActive, shotIdx, title, shotId, sceneId, isContent, sceneName }) => {
	const { id } = useParams();
	const [open, toggleModal] = useOpenModal();
	const titleRef = useRef<HTMLSpanElement>(null);
	const deleteShot = useAction(deleteShotAction);

	const handleDelete = () => {
		if (isContent) {
			toggleModal(true);
		} else {
			if (id) {
				deleteShot(id, { sceneId, shotId });
			}
		}
	};

	useKeyPress(
		"enter",
		() => {
			if (titleRef.current) {
				titleRef.current.innerText = titleRef.current.innerText.trim();
			}
		},
		{
			target: titleRef
		}
	);

	useEffect(() => {
		if (titleRef.current) {
			titleRef.current.innerText = title;
		}
	}, []);

	return (
		<>
			<Flex
				align="center"
				className={cn("shot__title cubic-animation gap-4", `shot__title__${color}`, {
					shot__title__active: isHover || isActive
				})}
			>
				<Typography.Text className={cn(TEXT_CLASSNAMES.XxsRegular, COLOR_CLASSNAMES.TextPrimary)}>
					Scene: {sceneName} Shot: {shotIdx + 1}
				</Typography.Text>
			</Flex>
			<Flex align="center" className={cn("shot__actions", {
				shot__actions__active: isHover || isActive
			})}>
				<Flex onClick={handleDelete} className="gap-xxs pointer shot__actions__button cubic-animation">
					<img src={deleteImg} alt="delete" />
					<Typography.Text className={TEXT_CLASSNAMES.XxsRegular}>Delete</Typography.Text>
				</Flex>
			</Flex>
			<DeleteShotModal sceneId={sceneId} shotId={shotId} toggleModal={toggleModal} isOpen={open} />
		</>
	);
};

import { Button, ConfigProvider, Flex, Segmented, Typography } from "antd";
import cn from "classnames";
import { FC, useMemo, useState } from "react";

import { RequestAccessModal } from "@/features/request-access-modal";
import { COLOR_NAMES } from "@/shared/const/color-names.ts";
import { TEXT_CLASSNAMES } from "@/shared/const/text-classnames.ts";
import { useOpenModal } from "@/shared/hooks";
import { useMediaQueries } from "@/shared/hooks/use-media-queries.ts";
import check from "@/assets/landing/check.svg";
import plus from "@/assets/landing/plus.svg";
import line from "@/assets/landing/pricing-line.svg";

import "./Pricing.scss";

type Currency = "$" | "€" | "£";

type Intent = "single" | "business" | "enterprise";

type Amount = Record<Intent, Record<Currency, string>>

export const Pricing: FC = () => {
	const { isLaptopS, isTablet } = useMediaQueries();
	const [open, toggleOpen] = useOpenModal();
	const [currency, setCurrency] = useState<Currency>("€");

	const amount: Amount = {
		single: {
			$: "109",
			"€": "99",
			"£": "79"
		},
		business: {
			$: "219",
			"€": "199",
			"£": "179"
		},
		enterprise: {
			$: "Contact us",
			"€": "Contact us",
			"£": "Contact us"
		}
	};

	const pricing = useMemo(() => {
		return [
			{
				title: "Single User",
				priceText: `${currency}${amount.single[currency]}`,
				interval: "/per month",
				options: [
					"1 user",
					"5 projects maximum",
					"Short scripts (3500 characters max)",
					"Watermarks"
				],
				additionalOptions: [],
				link: "",
				isContact: true,
				buttonText: "Choose"
			},
			{
				title: "Business",
				priceText: `${currency}${amount.business[currency]}`,
				interval: "/per month",
				options: [
					"20 users",
					"Unlimited projects",
					"Watermarks (option)"
				],
				additionalOptions: [
					"Custom characters",
					"Collaboration features",
					"Movie pitch",
					"Premium support"
				],
				link: "",
				isContact: true,
				buttonText: "Coming soon"
			},
			{
				title: "Enterprise",
				priceText: amount.enterprise[currency],
				interval: "",
				options: [
					"Unlimited users",
					"Unlimited projects",
					"No watermarks"
				],
				additionalOptions: [
					"On-premise deployment option",
					"Custom model training",
					"Supports multiple SAML configurations",
					"Integration with data loss prevention and offline backup providers",
					"HIPAA-compliant messaging and file collaboration"
				],
				link: "",
				isContact: true,
				buttonText: "Choose"
			}
		];
	}, [currency]);

	return (
		<Flex align="center" justify="center" vertical className="contacts">
			<Flex style={{ position: "relative", maxWidth: 700, width: "100%" }} vertical className="product-new__title gap-xs text-center">
				<Typography.Text className={cn(TEXT_CLASSNAMES.LPHeadlineH1, {
					[TEXT_CLASSNAMES.LPHeadlineH2]: isLaptopS,
					[TEXT_CLASSNAMES.LPHeadlineH4]: isTablet
				})}>Pricing <span className="product-new__title--italic">plans</span>
				</Typography.Text>
				<img className="product-new__action--line pricing-line" src={line} alt="line"/>
			</Flex>
			<Flex className="pricing__segmented" vertical gap={24}>
				<Flex justify="flex-end">
					<ConfigProvider theme={{
						components: {
							Segmented: {
								itemSelectedBg: COLOR_NAMES.MetalGray50
							}
						}
					}}>
						<Segmented
							size="large"
							value={currency}
							onChange={setCurrency}
							options={[{
								label: "€ EUR",
								value: "€"
							},
							{
								label: "$ USD",
								value: "$"
							},
							{
								label: "£ GBP",
								value: "£"
							}
							]}
							style={{
								border: "1px solid black",
								background: "transparent"
							}}
						/>
					</ConfigProvider>
				</Flex>
				<Flex wrap="wrap" justify="center" className="full-width" gap={16}>
					{pricing.map((price, index) => (
						<Flex flex={1} vertical gap={32} key={index.toString(36)} className={cn("pricing-block", {
							"pricing-block--active": !!price.link
						})}>
							<Typography.Title className={TEXT_CLASSNAMES.HeadlineH1}>{price.title}</Typography.Title>
							<Flex gap={16} vertical>
								<Flex vertical>
									<Typography.Text className={TEXT_CLASSNAMES.LPHeadlineH3}>{price.priceText}</Typography.Text>
									{!!price.interval && <Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{price.interval}</Typography.Text>}
								</Flex>
								<div style={{ width: "100%", height: 1, background: COLOR_NAMES.MetalGray400 }} />
								<Flex gap={8} vertical>
									{price.options.map((el, index) => (
										<Flex gap={8} align="center" key={index.toString(36)}>
											<img style={{ width: 24, height: 24 }} src={check} alt="check" />
											<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>{el}</Typography.Text>
										</Flex>
									))}
									{!!price.additionalOptions.length && (
										<>
											<img style={{ width: 24, height: 24, margin: "0 auto" }} src={plus} alt="plus" />
											{price.additionalOptions.map((el) => (
												<Flex gap={8} align="center" key={index.toString(36)}>
													<img style={{ width: 24, height: 24 }} src={check} alt="check" />
													<Typography.Text className={TEXT_CLASSNAMES.SmRegular}>{el}</Typography.Text>
												</Flex>
											))}
										</>
									)}
								</Flex>
								<Button onClick={() => {
									if (price.isContact) {
										toggleOpen(true);
									}
								}} style={{ marginTop: 8, border: `1px solid ${COLOR_NAMES.MetalGray400}` }}>
									<Typography.Text className={TEXT_CLASSNAMES.XsRegular}>{price.buttonText}</Typography.Text>
								</Button>
							</Flex>
						</Flex>
					))}
				</Flex>
			</Flex>
			<RequestAccessModal isOpen={open} toggleModal={toggleOpen} />
		</Flex>
	);
};
